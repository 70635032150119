import React from 'react';
import { useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { ReportTypes } from '~/mst/models/report/types';
import AQIReportContent from './type_contents/aqi_report';
import AQICrpReportContent from './type_contents/aqi_crp_report';
import PerimeterReportContent from './type_contents/perimeter_report';
import BasicReportContent from './type_contents/basic_report';

function ReportTypeContent() {
  const {
    values: { configuration }
  } = useFormState({ subscription: { values: true } });
  const { type } = configuration || {};
  switch (type) {
    case ReportTypes.air_quality:
      return <AQIReportContent />;
    case ReportTypes.perimeter_monitor:
      return <PerimeterReportContent />;
    case ReportTypes.air_quality_crp:
      return <AQICrpReportContent />;
    default:
      return <BasicReportContent />;
  }
}

export default observer(ReportTypeContent);
