/* eslint-disable import/no-extraneous-dependencies */
import * as Highcharts from 'highcharts/highstock';
import camelCase from 'lodash/camelCase';
import sortBy from 'lodash/sortBy';
import merge from 'lodash/merge';
import flatten from 'lodash/flatten';

import I18n from '~/utils/i18n';
import { PATHS } from '~/utils/constants';
import { hexToRgb } from '~/utils/helpers';
import { IDataPointNode } from '~/mst/models/data_point/node/default';
import { STATUS_COLORS } from '~/theme/types';
import { palettes } from './chart_palettes';

Highcharts.seriesTypes.spline.prototype.drawLegendSymbol = Highcharts.seriesTypes.pie.prototype.drawLegendSymbol;
Highcharts.seriesTypes.areaspline.prototype.drawLegendSymbol = Highcharts.seriesTypes.pie.prototype.drawLegendSymbol;
Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.pie.prototype.drawLegendSymbol;

export const colors = ['#71B6E1', '#476FA6', '#59D05E', '#3B8F26', '#674EAE', '#C879E3', '#1EC2C2', '#9FB0BF', '#419DA6'];

export const defaultChartOptions = {
  title: {
    text: null
  },
  colors,
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false
      }
    },
    zoomType: 'x',
    resetZoomButton: {
      position: {
        x: -10,
        y: -10
      }
    },
    marginTop: 20,
    defaultSeriesType: 'spline',
    backgroundColor: 'transparent'
  },
  colors,
  plotOptions: {
    series: {
      lineWidth: 2,
      egendSymbol: 'rectangle',
      states: {
        hover: {
          lineWidth: 2
        }
      },
      dataLabels: {
        enabled: false,
        crop: false,
        overflow: 'none',
        align: 'left',
        verticalAlign: 'middle'
      }
    },
    spline: {
      animation: false
    }
  },
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    minRange: 1000
  },
  legend: {
    enabled: true,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 6,
    itemStyle: {
      fontSize: '14px'
    }
  },
  rangeSelector: {
    enabled: false
  },
  navigator: {
    enabled: false,
    outlineWidth: 0
  },
  scrollbar: {
    enabled: false,
    barBorderRadius: 5,
    barBorderWidth: 0,
    buttonBorderWidth: 0,
    buttonBorderRadius: 7,
    trackBorderWidth: 0,
    trackBorderRadius: 7
  },
  lang: {
    resetZoom: I18n.t('chart_widget.reset_selected_range'),
    groupAxis: I18n.t('chart_widget.group_axis'),
    loading: I18n.t('base.labels.loading')
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px'
    }
  },
  exporting: {
    enabled: false
  }
};

export const getChartOptions = (options, theme = 'light') => {
  return merge(options, { ...palettes[theme] });
};

export const renderAxies = (theme, dataPoints, showZones, showLabels) => {
  const yAxises: any[] = [];
  dataPoints?.forEach((dp, index) => {
    const height = 100 / dataPoints.length;
    const yAxis = {
      top: showZones ? `${height * index}%` : undefined,
      height: showZones ? `${height}%` : undefined,
      offset: showZones ? 0 : undefined,
      unit: dp.unit,
      opposite: false,
      title: {
        text: showZones && showLabels && dp.name,
        offset: 50,
        style: {
          fontSize: '12px'
        }
      },
      gridLineWidth: '1',
      gridLineDashStyle: 'dash',
      gridLineColor: palettes[theme].gridLineColor,
      labels: {
        enabled: showLabels,
        style: {
          fontWeight: '400',
          color: palettes[theme].axisColor
        }
      }
    };
    if (yAxises.some(({ unit }) => unit === dp.unit) === false) {
      yAxises.push(yAxis);
    }
  });
  return yAxises;
};
function getZone(value, color, statusName, rgb) {
  return {
    value,
    color,
    statusName,
    fillColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [1, `rgba(${rgb}, 0.3)`],
        [0, `rgba(${rgb}, 0)`]
      ]
    }
  };
}
export const getZones = (ranges, statuses) => {
  const zones = sortBy(
    flatten(
      ranges?.map(({ lte, lt, gt, gte, status }) => {
        const statusName = camelCase(statuses[status]);
        const color = STATUS_COLORS[statusName];
        const rgb = hexToRgb(color).join(',');
        let value;
        if ((lt || lte) && (gt || gte)) {
          value = lt || lte;
        } else if (lt || lte) {
          return [getZone(lt || lte, color, statusName, rgb), getZone(0, color, statusName, rgb)];
        } else {
          return [getZone(gt || gte, color, statusName, rgb), getZone(undefined, color, statusName, rgb)];
        }
        return getZone(value, color, statusName, rgb);
      })
    ),
    'value'
  );
  return zones;
};

export const getThreshold = (dp: IDataPointNode) => {
  if (dp.shortPath === PATHS.CO2) {
    return 400;
  }
  return 0;
};

export const renderSeries = (theme, dataPoints, showZones, showDataGaps) => {
  const yAxis = renderAxies(theme, dataPoints, showZones);

  return dataPoints?.map((dp, index) => {
    const data = [...(dp.measurements || [])];
    return {
      id: dp?._id,
      precision: dp?.precision,
      unit: dp?.unit,
      name: dp?.presentName,
      type: dp.hasProfileRange && showZones ? 'areaspline' : 'spline',
      ordinal: !showDataGaps,
      gapSize: showDataGaps ? 5 : 0,
      threshold: null,
      data,
      marker: {
        enabled: false,
        symbol: 'circle'
      },
      yAxis: yAxis[index] ? index : yAxis.findIndex(({ unit }) => unit === dp.unit),
      zones: dp.hasProfileRange && showZones ? getZones(dp.profileRange, dp.profile.status_name) : [],
      ...(dp?.zones && { zones: dp?.zones })
    };
  });
};

export const renderXAxis = (theme, showDataGaps) => {
  return {
    ordinal: !showDataGaps,
    gapSize: showDataGaps ? 5 : 0,
    lineColor: palettes[theme].axisColor,
    tickColor: palettes[theme].axisColor,
    labels: {
      style: {
        color: palettes[theme].axisColor
      }
    }
  };
};
