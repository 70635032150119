import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { persist } from 'mobx-state-tree-persist';
import { autorun } from 'mobx';

import { PATHS } from '~/utils/constants';
import RangeSelector from '~/components/range_selector';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import useReportSubscribe from '~/hooks/use_dashboard_subscribe';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import { ChartProvider } from '~/hooks/use_chart';
import useQuery from '~/hooks/use_query';
import useNavigate from '~/hooks/use_navigate';
import Breadcrumbs from '~/components/breadcrumbs';
import DevicesDropdown from '~/components/navigate_dropdown/devices_dropdown';
import ActionsButton from '~/pages/devices/form_top/actions_button';
import DownloadButton from '~/pages/device_monitor/monitor_chart/download_button';
import Container from '~/components/container';
import { findTimezoneOption } from '~/utils/timezones';
import { MenuGroupTitleStyled, MenuItemStyled } from '~/components/actions_dropdown/styled';
import { CustomLocalStorage } from '~/mst/models/abstract/report';

import MonitorLayout from './layout';

const ranges = [
  { value: { from: { hours: 1 } } },
  { value: { from: { hours: 3 } } },
  { value: { from: { hours: 6 } } },
  { value: { from: { hours: 12 } } },
  { value: { from: { days: 1 } } },
  { value: { from: { days: 2 } } },
  { value: { from: { days: 7 } } }
];

function DeviceMonitor() {
  const { id } = useParams();
  const query = useQuery();
  const [persistedStore, setPersistedStore] = useState(null);
  const { t } = useLocales();
  const { nodes, deviceMonitor: monitor } = useMst();
  const { auth } = useMst();
  const { navigate } = useNavigate();

  const { isFetching } = useFetch(nodes, { includes: ['profiles'] });

  useEffect(
    () =>
      autorun(() => {
        if (nodes.isFetched && nodes.isFetching === false) {
          nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE], organization_id: auth.organizationId });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const node = nodes.getById(id);
  useEffect(() => {
    monitor.setDefaultTab();
    monitor.setWidgets([
      {
        id: 'chartWidget',
        type: 'ChartTableWidget',
        node_id: id
      },
      {
        id: 'nodeWidget',
        type: 'NodeWidget',
        node_id: id
      },
      {
        id: 'mapWidget',
        type: 'MapWidget',
        node_id: [id]
      }
    ]);
    if (query.has('parameter')) {
      monitor.params.setSelectedParamsChart([query.get('parameter')]);
      monitor.params.setSelectedParamsTable([query.get('parameter')]);
      localStorage.setItem(`device_monitor_${id}_params`, JSON.stringify(monitor.params));
    }

    setPersistedStore(
      persist([
        [monitor.range, { key: 'device_monitor_range', storage: CustomLocalStorage, whitelist: ['from', 'to', 'is_pinned'] }],
        [monitor.params, { key: `device_monitor_${id}_params`, storage: localStorage }]
      ])
    );
  }, [query, monitor, id]);

  const handleChangeRange = useCallback(
    (newRange) => {
      monitor.setRange(JSON.parse(newRange));
    },
    [monitor]
  );

  useReportSubscribe(monitor);

  useFetch(monitor?.nodeWidget, { includes: ['configuration', 'profiles', 'calibrations'], last: 20 });
  useFetch(monitor?.chartWidget, { includes: ['profiles', 'configuration', 'data_points'] });

  const timezone = monitor?.nodeWidget?.node?.timezone;

  useEffect(() => {
    if (timezone) {
      monitor?.range?.setTimezone(timezone);
    }
  }, [timezone, monitor?.range]);

  const isAdmin = auth.hasAdminDevice(node?.organization_id);

  const breadcrumbs = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      [
        { title: t('thiamis.monitor_page_breadcrumb'), to: `devices/${id}/monitor`, hasAccess: node?.isMonitorable },
        { title: t('thiamis.config_page_breadcrumb'), to: `devices/${id}/edit`, hasAccess: node?.isModifiable },
        { title: t('thiamis.admin_page_breadcrumb'), to: `devices/${id}/admin`, hasAccess: isAdmin }
      ]
    ],
    [id, t, node?.isModifiable, isAdmin, node?.isMonitorable]
  );

  if (node?.isCreatable) {
    return navigate(`devices/${id}/create`);
  }

  if (persistedStore?.isRehydrated && monitor?.range?.from == null) {
    monitor.range.setFrom({ hours: 3 });
  }

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <ChartProvider>
        <ExportProvider>
          <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 2 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              <DevicesDropdown
                onOpen={() => {
                  nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE], organization_id: auth.organizationId });
                }}
                onChange={(value) => navigate(`devices/${value}/monitor`)}
                options={nodes.monitorableValueLabelPairsSortedByStatus}
                loading={isFetching}
                value={id}
                model={monitor?.nodeWidget?.node}
              />
              <Box display="flex" justifyContent="center" flexDirection="column" sx={{ displayPrint: 'flex', display: 'none', textAlign: 'right' }}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {monitor.range.presentName}
                </Typography>
                <Typography variant="subtitle2">{findTimezoneOption(timezone)?.label}</Typography>
              </Box>
              <Box sx={{ displayPrint: 'none' }}>
                <Stack direction="column" gap={0}>
                  <RangeSelector
                    timezone={monitor?.nodeWidget?.node?.timezone}
                    value={JSON.stringify(monitor?.range?.toJSON())}
                    onChange={handleChangeRange}
                    isUpdatesOn={monitor.is_updates_on}
                    isPinned={monitor.range.is_pinned}
                    handleOnUpdates={monitor.turnOnUpdates}
                    handleOffUpdates={monitor.turnOffUpdates}
                    handleTogglePinned={monitor.range.togglePinned}
                    pinTimeText="Keep time range across devices"
                    allowCustom
                    pinable
                    options={ranges}
                  />
                  <Stack direction="row" gap={1} justifyContent="flex-end">
                    <SelectionProvider selected={[id]}>
                      <ActionsButton variant="outlined" canManage>
                        <MenuGroupTitleStyled key="download-menu-1" disabled>
                          {t('devices.actions.download')}
                        </MenuGroupTitleStyled>
                        <MenuItemStyled>
                          <DownloadButton report={monitor} />
                        </MenuItemStyled>
                      </ActionsButton>
                    </SelectionProvider>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            <MonitorLayout monitor={monitor} />
          </Stack>
        </ExportProvider>
      </ChartProvider>
    </Container>
  );
}

export default observer(DeviceMonitor);
