import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { observable, action, autorun } from 'mobx';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import useMst from '~/hooks/use_mst';

const params = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

export default function useDataPoints() {
  const { nodes } = useMst();
  const {
    values: {
      configuration: { node_ids: nodeIds }
    }
  } = useFormState({ subscription: { values: true } });

  useEffect(() => autorun(() => params.setNodeId(getOptionsForPayload(nodeIds).filter((id) => Boolean(nodes.getById(id))))), [nodes, nodeIds]);
  useEffect(() => autorun(() => nodes.fetchDataPoints({ node_id: params.node_id })), [nodes, nodeIds]);

  return {
    isFetching: nodes.isFetching,
    parameterOptions: nodes.defaultDataPointsValueLabelPairs
  };
}
