import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import compact from 'lodash/compact';

import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useSelection from '~/hooks/react_grid/use_selection';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';

import { captureException } from '~/services/sentry';
import TransferForm from './form';

type TransferModalProps = {
  toggleModal: VoidFunction;
};

const TransferNodesSchema = Yup.object().shape({
  selectedOrganization: searchableSelectSchema('Organization is required'),
  selectedBilling: searchableSelectSchema('Organization is required')
});

function TransferModal({ toggleModal }: TransferModalProps) {
  const { selection: selectedCalibrations, setSelection } = useSelection();
  const { t } = useLocales();

  const { nodes } = useMst();

  const validate = useValidationSchema(TransferNodesSchema);

  const handleTransfer = useCallback(
    async (values) => {
      const { selectedOrganization, selectedBilling } = values;
      const devices = compact(selectedCalibrations.map((nodeId) => nodes.getById(nodeId)));
      try {
        await Promise.all(devices?.map((node) => node.transfer(selectedOrganization, selectedBilling)));
        setSelection([]);
        toggleModal();
        toasts.success(t('notifications.success.calibration_device_transfer', { serials: devices.map((device) => device.serial).join(', ') }));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [selectedCalibrations, nodes, toggleModal, t, setSelection]
  );

  return (
    <FinalForm
      initialValues={{ selectedBilling: null, selectedOrganization: null, disabledNodes: [] }}
      onSubmit={handleTransfer}
      validate={validate}
      render={({ handleSubmit, valid }) => <TransferForm toggleModal={toggleModal} handleSubmit={handleSubmit} valid={valid} />}
    />
  );
}

export default observer(TransferModal);
