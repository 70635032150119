import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { IFrameRenderer } from '~/components/iframe_renderer';
import { IReport } from '~/mst/models/report';
import { token } from '~/api/axios';

const { VITE_ANALYTICS_URL_ROOT } = process.env;

function ReportContent({ report }: { report: IReport }) {
  const { name } = report || {};
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);

  return (
    <Card>
      <CardContent>
        {isIFrameLoaded === false && <div>Loading...</div>}
        <IFrameRenderer
          sourceUrl={`${VITE_ANALYTICS_URL_ROOT}reports/${report?.id}?token=${token.token}`}
          title={name}
          handleOnLoad={() => {
            setIsIFrameLoaded(true);
          }}
        />
      </CardContent>
    </Card>
  );
}

export default React.memo(ReportContent);
