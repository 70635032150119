import { useTheme } from '@mui/material/styles';
import { Grid, Box, Typography, Stack } from '@mui/material';
import { getIdentifier } from '~/mst/utils';
import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';

import { StatusIconWithAutoUpdate } from '~/components/widgets/node_widget/content/name_formatter';
import StyledTooltip from '~/components/@material-extend/MTooltip';
import { IDevice } from '~/mst/models/device';
import type { IDustTrakSensor } from '~/mst/models/device/sensor/dust_trak';
import useSvgLoader from '~/hooks/use_svg_loader';
import { Icon, DeviceCard } from './components';
import SensorDataPoint from './sensor_datapoint';

interface SensorProps {
  device: IDevice | IDustTrakSensor;
}

function TooltipContent({ device }: { device: IDevice }) {
  return (
    <Grid container spacing={1}>
      {device.defaultDataPoints?.map((dp) => <SensorDataPoint key={getIdentifier(dp)} datapoint={dp} />)}
    </Grid>
  );
}

function Sensor(props: SensorProps) {
  const theme = useTheme();
  const { device } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SvgIcon = useSvgLoader(device.model, device.name);

  return (
    <StyledTooltip
      title={!isEmpty(device.defaultDataPoints) ? <TooltipContent device={device} /> : null}
      arrow
      placement="bottom"
      sx={{ minWidth: 'unset', maxWidth: '500px', padding: theme.spacing(2) }}
    >
      <DeviceCard>
        <Icon $fixheight $sensor>
          <SvgIcon />
        </Icon>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(-0.25),
            paddingLeft: theme.spacing(0)
          }}
        >
          <StatusIconWithAutoUpdate model={device} size="xsmall" />
          <Box>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {device.model}
              </Typography>
            </Stack>
            {device.tag && (
              <Typography variant="caption" display="block" sx={{ lineHeight: 0.35 }} color={theme.palette.grey[900]}>
                <small>{device.tag}</small>
              </Typography>
            )}
            {device.serial && (
              <Box mt={1}>
                <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                  S/N
                </Typography>
                <Typography variant="body3"> {device.serial}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DeviceCard>
    </StyledTooltip>
  );
}

export default observer(Sensor);
