/* eslint-disable @typescript-eslint/naming-convention */
import { Instance, types as t, SnapshotOut } from 'mobx-state-tree';
import trim from 'lodash/trim';
import { isAethairProDevice, isAirthinxDevice } from '~/mst/models/device/utils';
import DataPointNodeBase from './base';

const DataPointNode = t
  .compose(
    DataPointNodeBase,
    t
      .model({
        unit: t.frozen(t.optional(t.string, '')),
        device_id: t.frozen(t.maybeNull(t.string)),
        node_id: t.frozen(t.string),
        round: t.frozen(t.optional(t.number, 0)),
        name: t.frozen(t.maybeNull(t.string))
      })
      .views((self) => ({
        get presentName() {
          if (self.unit) {
            return trim(`${self.name}, ${self.unit}`);
          }
          return self.name;
        },
        get shortPresentName() {
          return self.name;
        },
        get hasDevice() {
          if (isAethairProDevice(self.device_id) || isAirthinxDevice(self.device_id)) {
            return true;
          }
          return !!self.node?.devices?.getById(self.device_id);
        }
      }))
  )
  .named('DataPointNode');

export interface IDataPointNode extends Instance<typeof DataPointNode> {}
export interface IDataPointNodeSnapshotOut extends SnapshotOut<typeof DataPointNode> {}

export default DataPointNode;
