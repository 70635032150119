/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { SxProps } from '@mui/system';
import { TextAreaFormControl, TextareaAutosizeStyled, StyledInputLabel, StyledFormHelperText } from './styled';

interface Props {
  name: string;
  label?: string;
  sx?: SxProps;
  multiline?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  noHelperText?: boolean;
  minRows?: number;
  placeholder?: string;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any;
}

const identity = (value: any) => value;

export default function RFFTextInput(props: Props) {
  const { name, validate, helperText, label, noHelperText = false, sx, minRows = 3, ...rest } = props;
  return (
    <FinalField name={name} validate={validate} parse={identity}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        const errorMessage = hasError ? meta.error : undefined;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [focused, setFocused] = useState(false);
        const hasValue = !!input.value;

        return (
          <TextAreaFormControl sx={sx} error={!!hasError} fullWidth>
            {label && (
              <StyledInputLabel error={!!hasError} focused={focused} hasValue={hasValue}>
                {label}
              </StyledInputLabel>
            )}
            <TextareaAutosizeStyled
              {...rest}
              {...input}
              error={!!hasError}
              focused={focused}
              hasValue={hasValue}
              minRows={minRows}
              onFocus={(e) => {
                setFocused(true);
                if (input.onFocus) {
                  input.onFocus(e);
                }
              }}
              onBlur={(e) => {
                setFocused(false);
                if (input.onBlur) {
                  input.onBlur(e);
                }
              }}
            />
            {!noHelperText && (errorMessage || helperText) && (
              <StyledFormHelperText error={!!hasError}>{errorMessage || helperText}</StyledFormHelperText>
            )}
          </TextAreaFormControl>
        );
      }}
    </FinalField>
  );
}
