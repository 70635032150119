import React, { useEffect, useMemo } from 'react';
import { CardContent } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import groupBy from 'lodash/fp/groupBy';
import compact from 'lodash/fp/compact';
import sortBy from 'lodash/fp/sortBy';
import { action, autorun, computed, observable } from 'mobx';

import { useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';

import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import DevicesSelect from '~/components/devices_select';
import Autocomplete from '~/components/final_form/select';
import DateTimePicker from '~/components/final_form/date_time_picker';
import { ModalActions } from '~/components/modal';
import FormGrid from '~/components/@material-extend/form_grid';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { PATHS } from '~/utils/constants';

type ModalFormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
};

const params = observable(
  {
    node_id: null,
    last: 1,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

function ModalForm({ handleCancel, handleSubmit }: ModalFormProps) {
  const { t } = useLocales();
  const { nodes } = useMst();
  const { change } = useForm();
  const {
    values: { devices, from, to },
    hasValidationErrors
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  useEffect(() => params.setNodeId(getOptionsForPayload(devices)), [devices]);
  useEffect(() => autorun(() => nodes.fetchDataPoints({ node_id: params.node_id, last: 1 })), [nodes, devices]);

  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map(({ model }) => model), label: name, value: name })),
          sortBy('label')
        )(nodes.defaultDataPointsValueLabelPairs);
      }),
    [nodes.defaultDataPointsValueLabelPairs]
  ).get();

  const timezone = useMemo(() => computed(() => nodes.getDataPointLastValueByPathNodeIds(params.node_id, PATHS.TIMEZONE)), [nodes]).get();
  useEffect(() => change('timezone', timezone), [change, timezone]);

  const now = Date.now();
  return (
    <>
      <CardContent>
        <FormGrid>
          <DevicesSelect
            multiple
            allowSelectAll
            name="devices"
            label={t('thiamis.download_csv.devices')}
            options={nodes.monitorableValueLabelPairs}
          />
          <Autocomplete
            searchable
            multiple
            allowSelectAll
            name="parameters"
            label={t('thiamis.download_csv.parameters')}
            options={parameterOptions}
            loading={nodes.isFetching}
          />
          <FormGrid columns="2">
            <DateTimePicker name="from" label={t('thiamis.download_csv.from')} maxDateTime={to || now} timezone={timezone} />
            <DateTimePicker name="to" label={t('thiamis.download_csv.to')} maxDateTime={now} minDate={from} timezone={timezone} />
          </FormGrid>
        </FormGrid>
      </CardContent>
      <OnChange name="devices">
        {() => {
          change('parameters', []);
          nodes.resetDataPoints();
        }}
      </OnChange>
      <ModalActions onSave={handleSubmit} onClose={handleCancel} saveLabel={t('base.buttons.download')} disabled={hasValidationErrors} />
    </>
  );
}

export default observer(ModalForm);
