import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import 'highcharts/modules/no-data-to-display';
import 'highcharts/modules/exporting';
import 'highcharts/highcharts-more';
import useChart from '~/hooks/use_chart';
import debounce from 'lodash/debounce';

import { defaultChartOptions } from './chart_options';

type HighchartsWindSeries = {
  name: string;
  data: [string, number];
};

function Chart({ series, options }: { series: HighchartsWindSeries[]; options: any }) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef(null);
  const [chartOptions, setChartOptions] = useState(defaultChartOptions);

  useEffect(
    () =>
      autorun(() =>
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          ...options,
          series,
          exporting: {
            enabled: false
          }
        }))
      ),
    [series, options]
  );

  const resizeObserver = useRef(
    new ResizeObserver(
      debounce(() => {
        if (chartRef.current) {
          chartRef.current.chart.reflow();
        }
      }, 250)
    )
  );

  const { setChartRef } = useChart();

  useEffect(() => {
    if (chartRef.current) {
      setChartRef(chartRef);
    }
  }, [chartRef, setChartRef]);

  useEffect(() => {
    const resizeObserverInstance = resizeObserver.current;
    const containerRefInstance = containerRef.current;
    if (containerRefInstance) {
      resizeObserverInstance.observe(containerRefInstance);
    }
    return () => {
      if (containerRefInstance) {
        resizeObserverInstance.unobserve(containerRefInstance);
      }
    };
  }, [containerRef, resizeObserver]);

  return (
    <Box ref={containerRef} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} containerProps={{ style: { height: '100%' } }} options={chartOptions} />
    </Box>
  );
}

export default observer(Chart);
