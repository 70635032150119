import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { CardContent, FormHelperText } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';

import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useSelection from '~/hooks/react_grid/use_selection';

import FormGrid from '~/components/@material-extend/form_grid';
import OrganizationsSelect from '~/components/organizations_select';
import { ModalActions } from '~/components/modal';
import useFetch from '~/hooks/use_fetch';
import { action, computed, observable } from 'mobx';
import ModalTable from '../modal_table';

type TransferModalProps = {
  toggleModal: VoidFunction;
  handleSubmit: VoidFunction;
  valid: boolean;
};

const params = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);

function TransferModal({ handleSubmit, toggleModal, valid }: TransferModalProps) {
  const { selection: selectedCalibrations } = useSelection();
  const { t } = useLocales();
  const { change } = useForm();
  const {
    values: { selectedOrganization }
  } = useFormState({ subscription: { values: true } });

  const { organizations, nodes, partnerships } = useMst();
  const isUpdating = selectedCalibrations.some((id) => nodes.getById(id)?.isSyncing);

  useEffect(() => params.setOrganizationId(selectedOrganization), [selectedOrganization]);
  useFetch(partnerships, params);

  const partnersOptions = useMemo(
    () =>
      computed(() => {
        if (selectedOrganization) {
          return [{ value: selectedOrganization, label: organizations.getById(selectedOrganization)?.name }].concat(
            partnerships.partnersValueLabelPais.filter(({ model }) => {
              return selectedOrganization === model.organizationId;
            })
          );
        }
        return [];
      }),
    [partnerships.partnersValueLabelPais, selectedOrganization, organizations]
  ).get();

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);

  return (
    <>
      <CardContent>
        <FormGrid>
          <OrganizationsSelect name="selectedOrganization" label={t('thiamis.owner')} options={organizations.valueLabelPairs} />
          <OrganizationsSelect name="selectedBilling" loading={partnerships.isFetching} label={t('thiamis.billing')} options={partnersOptions} />
          <ModalTable showBilling />
          <FormHelperText dangerouslySetInnerHTML={{ __html: t('thiamis.manage_dialog.transfer_help_text') }} />
        </FormGrid>
      </CardContent>
      <OnChange name="selectedOrganization">
        {(value) => {
          if (value) {
            change('selectedBilling', null);
            params.setOrganizationId(value);
          }
        }}
      </OnChange>
      <ModalActions onSave={handleSubmit} saveLabel={t('base.buttons.transfer')} onClose={handleCancel} disabled={!valid} loading={isUpdating} />
    </>
  );
}

export default observer(TransferModal);
