/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Yup, { parseDate } from '~/utils/yup';
import { observer } from 'mobx-react-lite';
import arrayMutators from 'final-form-arrays';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { useValidationSchema } from '~/hooks/use_validate';
import { captureException } from '~/services/sentry';
import type { IAnalyticsStore } from '~/mst/models/analytics';
import Form from './form';

const AnalyticsSchema = Yup.object().shape({
  from: Yup.date().transform(parseDate).required('Required'),
  to: Yup.date().transform(parseDate).required('Required'),
  node_ids: Yup.array().required('Required').min(1, 'Device is required'),
  parameters: Yup.array().required('Required').min(1, 'Parameter is required')
});

function AnalyticsForm({ model }: { model: IAnalyticsStore }) {
  const { t } = useLocales();
  const validate = useValidationSchema(AnalyticsSchema);

  const handleFormSubmit = useCallback(
    async (values) => {
      const { node_ids: nodeIds, parameters } = values;
      const payload = {
        ...values,
        node_ids: getOptionsForPayload(nodeIds),
        parameters: getOptionsForPayload(parameters)
      };
      try {
        await model.noesis(payload);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [model, t]
  );
  const initialValues = useMemo(
    () => ({
      prompt:
        'Given the following hourly average data for parameters levels, provide a summary focusing on any trends, peaks, or concerns.\nHighlight any implications for air quality, environmental impact, or health risks.\nProvide insights on abnormal readings if present, suggest possible reasons and solutions.\n\n'
    }),
    []
  );

  return (
    <FinalForm
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleFormSubmit}
      subscription={{ submitting: true, pristine: true, dirty: true }}
      render={({ handleSubmit, invalid, submitting }) => <Form handleSubmit={handleSubmit} model={model} invalid={invalid} submitting={submitting} />}
    />
  );
}

export default observer(AnalyticsForm);
