/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';
import api from '~/api';

import { toMapById } from '~/mst/utils';
import type { IHeatmapWidgetModel } from './model';

export default (self: IHeatmapWidgetModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const payload = {
        includes: ['profiles', 'configuration'],
        data_point_id: [...self.data_point_id],
        node_id: [...self.node_id],
        last: 1
      };
      const { data } = yield self.sync(api.search.nodes, payload);
      const linkedDataPointIds = compact(flattenDeep(data.map(({ data_points }) => data_points.map(({ linked }) => linked))));
      if (!isEmpty(linkedDataPointIds)) {
        const { data: linkedDataPoints } = yield self.sync(api.search.dataPoints, { data_point_id: linkedDataPointIds });
        applySnapshot(self.nodes, {
          models: toMapById(
            data.map((node) => ({
              ...node,
              data_points: [...node.data_points, ...linkedDataPoints.filter(({ node_id }) => node_id === node._id)]
            }))
          )
        });
      } else {
        applySnapshot(self.nodes, {
          models: toMapById(data)
        });
      }
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setFilter(value) {
    self.filter = value;
  }
});
