import React, { useCallback, useState } from 'react';
import { Menu, MenuItem, Box } from '@mui/material';
import { saveAs } from 'file-saver';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonDesktopOnly from '~/components/adaptive_button';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import { downloadPdf } from '~/api/rest/reports';
import { IReport } from '~/mst/models/report';

function ReportContent({ report }: { report: IReport }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useLocales();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDownloadPdf = useCallback(async () => {
    handleClose();
    try {
      toasts.info(t('notifications.info.creating_pdf_report'));
      const { data } = await downloadPdf(report?.id);
      saveAs(data, `Report ${report?.name}.pdf`);
      toasts.close();
    } catch (error) {
      toasts.error(t('notifications.errors.invalid_pdf_response'));
    }
  }, [report, handleClose, t]);

  const open = Boolean(anchorEl);

  return (
    <Box>
      <ButtonDesktopOnly
        aria-haspopup="true"
        variant="contained"
        size="medium"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        fullWidth
        breakpoint="sm"
      >
        {t('devices.actions.title')}
      </ButtonDesktopOnly>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiSvgIcon-root': {
              marginRight: 2
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleDownloadPdf}>{t('base.buttons.download_pdf')}</MenuItem>
      </Menu>
    </Box>
  );
}

export default React.memo(ReportContent);
