import React from 'react';

import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';
import mapInlineStyles from './inline/map';

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },
        '.highcharts-tooltip .highcharts-label > span': {
          padding: '0.5rem 0.5rem',
          borderRadius: '10px',
          boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 12px 24px 0 rgb(145 158 171 / 24%)'
        },
        '.highchart-overflow_wrapper, .highchart-overflow_wrapper svg.highcharts-root, .highchart-overflow_wrapper > .highcharts-container': {
          overflow: 'visible !important'
        },
        '.highcharts-background': {
          fill: 'transparent',
          background: 'transparent'
        },
        '.highcharts-legend .highcharts-graph': {
          strokeWidth: '2px'
        },
        '.react-grid-item.react-grid-placeholder': {
          backgroundColor: '#826AF9 !important'
        },
        '.MuiDateRangeCalendar-root > div:first-child': {
          display: 'none !important'
        },
        '.highcharts-pie-center-text': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '@media print': {
          '.notistack-Snackbar': {
            display: 'none !important'
          },
          '@media (orientation: landscape)': {
            '.highchart-overflow_wrapper, .highchart-overflow_wrapper svg.highcharts-root, .highchart-overflow_wrapper > .highcharts-container': {
              width: '580px !important'
            }
          }
        },
        ...mapInlineStyles
      }}
    />
  );
}
