import { EnumAsUnion } from '~/utils/enum_as_union';

export enum ReportTypes {
  air_quality = 'air_quality',
  air_quality_crp = 'air_quality_crp',
  perimeter_monitor = 'perimeter_monitor'
}

export type ReportTypesUnionType = EnumAsUnion<typeof ReportTypes>;
export type ReportTypesRecordType = Record<ReportTypesUnionType, string[]>;
