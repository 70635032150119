/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Box, FormControl } from '@mui/material';
import { Field as FinalField } from 'react-final-form';

import Autocomplete from './autocomplete';
import Simple from './simple';
import type { AutocompleteProps } from './types';

const identity = (value) => value;

export default function RFFSelect(props: AutocompleteProps) {
  const { name, searchable, sx = {}, validate, helperText, noHelperText, ...rest } = props;

  return (
    <FinalField name={name} validate={validate} allowNull parse={identity}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;

        return (
          <Box sx={{ ...sx, width: '100%' }}>
            <FormControl error={Boolean(hasError)} fullWidth>
              {searchable ? (
                <Autocomplete {...input} {...rest} helperText={noHelperText ? null : hasError || helperText} error={Boolean(hasError)} />
              ) : (
                <Simple {...input} {...rest} helperText={noHelperText ? null : hasError || helperText} error={Boolean(hasError)} />
              )}
            </FormControl>
          </Box>
        );
      }}
    </FinalField>
  );
}
