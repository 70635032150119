import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { SelectProps } from '@mui/material/Select';
import kebabCase from 'lodash/kebabCase';
import { FormControl, IconButton, Tooltip, Chip, MenuItem, ClickAwayListener, Stack, Button } from '@mui/material';
import { PlayArrow, PauseCircle, SkipNext, SkipPrevious, DateRange, ArrowDropDown, ArrowDropUp, PushPin } from '@mui/icons-material';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import humanizer from '~/utils/humanize_duration';
import useLocales from '~/hooks/use_locales';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputDateTimeRangeField';
import { ButtonGroupStyled, PaperStyled, TextFieldStyled, ChipStyled } from './styled';

export const CUSTOM_RANGE = 'CUSTOM_RANGE';

type OptionType = {
  value: {
    from: string | number;
    to?: string | number;
  };
};

function getOptionLabel(value: [DateTime, DateTime]) {
  if (isEmpty(value)) {
    return '';
  }
  return `Last ${humanizer.humanizeDurationLong(value[1].diff(value[0]))}`;
}

function RangeChip({ value }: { value: [DateTime, DateTime] }) {
  if (isEmpty(value)) {
    return '';
  }
  const label = humanizer.humanizeDuration(value[1].diff(value[0]), { smallestUnit: 'min' });
  return <ChipStyled data-testid={`chip-${label}`} label={label} sx={{ mr: 1, minWidth: 55 }} />;
}

function getValueFromJSON(value: string) {
  const now = DateTime.now().toMillis();
  const { from, to } = JSON.parse(value);
  try {
    if (to) {
      return [DateTime.fromMillis(from), DateTime.fromMillis(to)];
    }
    if (from) {
      return [DateTime.fromMillis(now).minus(from), DateTime.fromMillis(now)];
    }
  } catch (e) {}
  return [];
}

function getIsRangeInPast(value: string) {
  const [, to] = getValueFromJSON(value);
  if (to) {
    const now = DateTime.now();
    const diffFromNow = now.diff(to).toMillis();
    return diffFromNow > 60 * 60 * 1000;
  }
  return false;
}

type RangeSelectorProps = SelectProps<string> & {
  value?: string;
  timezone?: string;
  onChange: (value: string) => void;
  handleOffUpdates: () => void;
  handleOnUpdates: () => void;
  handleTogglePinned: (value: boolean) => void;
  allowCustom?: boolean;
  isDateTimeRangeReadOnly?: boolean;
  size?: 'medium' | 'small';
  options: OptionType[];
  pinable?: boolean;
  pinTimeText?: string;
  allowNavigation?: boolean;
  isUpdatesOn?: boolean;
  isPinned?: boolean;
};

function RangeSelector({
  options,
  timezone,
  allowCustom,
  pinTimeText,
  size = 'small',
  value,
  onChange,
  pinable = false,
  allowNavigation = true,
  isUpdatesOn = true,
  handleOffUpdates,
  handleOnUpdates,
  isPinned,
  handleTogglePinned,
  isDateTimeRangeReadOnly = false
}: RangeSelectorProps) {
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownOpened = Boolean(anchorEl);
  const [localValue, setLocalValue] = useState(value || JSON.stringify(options[0].value));
  const [isDatetimeFieldVisible, setIsDatetimeFieldVisible] = useState(false);
  const [isCalendarOpen, openCalendar] = useState(false);
  const detetimeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const isRangeInPast = getIsRangeInPast(localValue);
    setIsDatetimeFieldVisible(isRangeInPast);
    if (isRangeInPast) {
      handleOffUpdates();
    } else {
      handleOnUpdates();
    }
  }, [localValue, handleOffUpdates, handleOnUpdates]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const mappedOptions = useMemo(() => {
    if (allowCustom) {
      return [
        ...options,
        {
          value: CUSTOM_RANGE,
          label: 'Select custom range'
        }
      ];
    }
    return options;
  }, [options, allowCustom]);

  const isNextDisabled = useMemo(() => {
    const [from, to] = getValueFromJSON(localValue);
    if (from && to) {
      const diff = to.diff(from);
      const fromNext = from.plus(diff).toMillis();
      const toNext = to.plus(diff).toMillis();
      const now = DateTime.now().toMillis();
      return fromNext > now || toNext > now;
    }
    return true;
  }, [localValue]);

  const handlePinClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleTogglePinned(!isPinned);
    },
    [isPinned, handleTogglePinned]
  );

  const handleChange = useCallback(
    (newValue: string) => {
      setLocalValue(newValue);
      onChange(newValue);
    },
    [setLocalValue, onChange]
  );

  const handleOpenClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    openCalendar(false);
  }, [setAnchorEl]);

  const handleToggleDropDownClick = useCallback(
    (event) => {
      event.preventDefault();
      if (isDropdownOpened) {
        handleClose();
      } else {
        handleOpenClick(event);
      }
    },
    [handleClose, handleOpenClick, isDropdownOpened]
  );

  const handleSkipPrevios = useCallback(() => {
    const [from, to] = getValueFromJSON(localValue);
    const diff = to.diff(from);
    setIsDatetimeFieldVisible(true);
    handleChange(JSON.stringify({ from: from.minus(diff).toMillis(), to: to.minus(diff).toMillis() }));
  }, [localValue, handleChange]);

  const handleSkipNext = useCallback(() => {
    const [from, to] = getValueFromJSON(localValue);
    const diff = to.diff(from);
    setIsDatetimeFieldVisible(true);
    handleChange(JSON.stringify({ from: from.plus(diff).toMillis(), to: to.plus(diff).toMillis() }));
  }, [localValue, handleChange]);

  const handleToggleUpdatesClick = useCallback(() => {
    const [from, to] = getValueFromJSON(localValue);
    if (isUpdatesOn) {
      handleOffUpdates();
    } else {
      handleOnUpdates();
      const now = DateTime.now();
      const diff = to.diff(from);
      setIsDatetimeFieldVisible(false);
      handleChange(JSON.stringify({ from: now.minus(diff).toMillis(), to: now.toMillis() }));
    }
  }, [localValue, isUpdatesOn, handleOffUpdates, handleOnUpdates, handleChange]);

  const handleMenuClick = useCallback(
    (event) => {
      const newValue = event.target.dataset.value;
      if (newValue === CUSTOM_RANGE) {
        openCalendar(true);
        setAnchorEl(null);
      } else if (newValue) {
        handleChange(newValue);
        handleClose();
        setIsDatetimeFieldVisible(false);
      }
    },
    [handleChange, handleClose]
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <FormControl size={size} fullWidth data-testid="rs-form-control">
        <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
          <Stack direction="row" gap={1}>
            {isDropdownOpened || isCalendarOpen || isDatetimeFieldVisible ? (
              <DateTimeRangePicker
                ampm={false}
                slots={{ field: SingleInputDateTimeRangeField }}
                open={isCalendarOpen}
                data-testid="rs-datetime-range-field"
                timezone={timezone}
                disableDragEditing
                readOnly={isDateTimeRangeReadOnly}
                ref={detetimeInputRef}
                closeOnSelect={false}
                sx={{ minWidth: 380 }}
                disableFuture
                format="LLL dd, HH:mm"
                onChange={([from, to]) => {
                  if (from?.isValid && to?.isValid && !isCalendarOpen) {
                    handleChange(JSON.stringify({ from: from.toMillis(), to: to.toMillis() }));
                  }
                }}
                onAccept={([from, to]) => {
                  if (from?.isValid && to?.isValid) {
                    handleChange(JSON.stringify({ from: from.toMillis(), to: to.toMillis() }));
                    openCalendar(false);
                  }
                }}
                value={getValueFromJSON(localValue)}
                defaultValue={[DateTime.now().minus({ minutes: 30 }), DateTime.now()]}
                slotProps={{
                  popper: {
                    anchorEl: detetimeInputRef.current
                  },
                  field: {
                    size,
                    InputProps: {
                      startAdornment: <RangeChip value={getValueFromJSON(localValue)} />,
                      endAdornment: (
                        <>
                          {pinable && (
                            <Tooltip placement="top" title={isPinned ? 'Unpin time selection' : (pinTimeText ?? 'Keep time range')}>
                              <IconButton data-testid="pin-button" size="small" onClick={handlePinClick} data-dd-action-name="rs::pin_btn_clicked">
                                <PushPin fontSize="small" color={isPinned ? 'primary' : 'info'} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton data-testid="rs-toogle-dropdown-btn" size="small" onClick={handleToggleDropDownClick}>
                            {isDropdownOpened ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                          </IconButton>
                        </>
                      )
                    }
                  }
                }}
              />
            ) : (
              <TextFieldStyled
                data-testid="rs-text-field"
                onClick={handleOpenClick}
                value={getOptionLabel(getValueFromJSON(localValue))}
                size={size}
                InputProps={{
                  startAdornment: <RangeChip value={getValueFromJSON(localValue)} />,
                  endAdornment: (
                    <>
                      {pinable && (
                        <Tooltip placement="top" title={isPinned ? 'Unpin time selection' : (pinTimeText ?? 'Keep time range')}>
                          <IconButton data-testid="rs-pin-btn" size="small" onClick={handlePinClick} data-dd-action-name="rs::pin_btn_clicked">
                            <PushPin fontSize="small" color={isPinned ? 'primary' : 'info'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton data-testid="rs-toogle-dropdown-btn" size="small" onClick={handleToggleDropDownClick}>
                        {isDropdownOpened ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                      </IconButton>
                    </>
                  )
                }}
              />
            )}
          </Stack>
          {allowNavigation && (
            <Stack direction={{ xs: 'row', md: 'column' }} gap={1} sx={{ pb: 1 }} justifyContent="flex-end">
              <ButtonGroupStyled size={size} variant="outlined">
                <Button onClick={handleSkipPrevios} data-dd-action-name="rs::prev_btn_clicked" data-testid="rs-prev-btn">
                  <SkipPrevious />
                </Button>
                <Tooltip title={`${isUpdatesOn ? t('base.buttons.pause_live_updates') : t('base.buttons.resume_live_updates')}`} placement="top">
                  <Button onClick={handleToggleUpdatesClick} data-dd-action-name="rs::toggle_updates_btn_clicked" data-testid="rs-toggle-updates-btn">
                    {isUpdatesOn ? <PauseCircle /> : <PlayArrow />}
                  </Button>
                </Tooltip>
                <Button onClick={handleSkipNext} disabled={isNextDisabled} data-dd-action-name="rs::next_btn_clicked" data-testid="rs-next-btn">
                  <SkipNext />
                </Button>
              </ButtonGroupStyled>
            </Stack>
          )}
        </Stack>
        {isDropdownOpened && (
          <PaperStyled elevation={1}>
            {mappedOptions.map((option) => {
              if (option.value === CUSTOM_RANGE) {
                return (
                  <MenuItem
                    key={option.value}
                    data-value={option.value}
                    onClick={handleMenuClick}
                    data-testid="rs-custom-range-option-clicked"
                    data-dd-action-name="rs::custom_range_option_clicked"
                  >
                    <Chip
                      icon={<DateRange />}
                      sx={{
                        mr: 1,
                        minWidth: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > span': { display: 'none' },
                        '& > svg': { margin: '0px !important' }
                      }}
                    />
                    {option.label}
                  </MenuItem>
                );
              }
              const optionValue = JSON.stringify(option.value);
              const optionValueParsed = getValueFromJSON(optionValue);
              return (
                <MenuItem
                  key={getOptionLabel(optionValueParsed)}
                  data-value={optionValue}
                  onClick={handleMenuClick}
                  data-testid={`rs-option-${kebabCase(optionValue)}`}
                  data-dd-action-name={`rs::${kebabCase(optionValue)}_option_clicked`}
                >
                  <RangeChip value={optionValueParsed} />
                  {getOptionLabel(optionValueParsed)}
                </MenuItem>
              );
            })}
          </PaperStyled>
        )}
      </FormControl>
    </ClickAwayListener>
  );
}

export default observer(RangeSelector);
