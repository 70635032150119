import { styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/base';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';

// Define custom prop interfaces
interface StyledTextareaProps {
  error?: boolean;
  focused?: boolean;
  hasValue?: boolean;
}

interface StyledInputLabelProps {
  error?: boolean;
  focused?: boolean;
  hasValue?: boolean;
}

// Styled FormControl to wrap the textarea and provide the TextField-like container
export const TextAreaFormControl = styled(FormControl)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: theme.spacing(2)
}));

// Styled TextareaAutosize to look like MUI TextField
export const TextareaAutosizeStyled = styled(TextareaAutosize)<StyledTextareaProps>(({ theme, error, focused }) => ({
  width: '100%',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  lineHeight: '1.4375em',
  padding: '16.5px 14px',
  borderRadius: theme.shape.borderRadius,
  // eslint-disable-next-line no-nested-ternary
  border: `1px solid ${error ? theme.palette.error.main : focused ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  resize: 'vertical',
  minHeight: '56px',
  '&:focus': {
    outline: 'none',
    borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    boxShadow: `${error ? theme.palette.error.main : theme.palette.primary.main} 0 0 0 1px`
  },
  '&:hover': {
    borderColor: error ? theme.palette.error.main : theme.palette.text.primary
  },
  '&::placeholder': {
    color: 'transparent' // Hide placeholder when label is shown
  },
  '&:disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    cursor: 'not-allowed'
  }
}));

// Styled InputLabel to mimic TextField's floating label
export const StyledInputLabel = styled(InputLabel)<StyledInputLabelProps>(({ theme, error, focused, hasValue }) => ({
  position: 'absolute',
  pointerEvents: 'none',
  transformOrigin: 'left top',
  transition: theme.transitions.create(['transform', 'font-size', 'color'], {
    duration: '200ms',
    easing: 'ease'
  }),
  ...(focused || hasValue
    ? {
        transform: 'translate(14px, -9px) scale(0.75)',
        backgroundColor: theme.palette.background.paper,
        padding: '0 5px',
        // eslint-disable-next-line no-nested-ternary
        color: focused ? theme.palette.primary.main : error ? theme.palette.error.main : theme.palette.text.primary
      }
    : {
        transform: 'translate(14px, 16px) scale(1)',
        color: error ? theme.palette.error.main : theme.palette.text.secondary
      })
}));

// Styled FormHelperText to match TextField's helper text
export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em'
}));
