// src/pages/report_edit/form/details_block/parameters/perimeter_parameters.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, Typography, Card, CardContent, InputAdornment, Grid } from '@mui/material';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import { useFormState } from 'react-final-form';
import useLocales from '~/hooks/use_locales';
import useDataPoints from './hooks/use_data_points';
import DataPointsSelect from './data_points_select';

function PerimeterParameter({ index, parameterOptions, loading }: { index: number; parameterOptions: any; loading: boolean }) {
  const { t } = useLocales();
  const {
    values: {
      configuration: { parameters }
    }
  } = useFormState({ subscription: { values: true } });

  const parameter = parameters[index];

  return (
    <Card key={parameter.name} variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="subtitle1">{parameter.name}</Typography>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <DataPointsSelect
              multiple
              name={`configuration.parameters[${index}].data_point_ids`}
              label={t('models.parameter_plural')}
              options={parameterOptions}
              loading={loading}
            />
            <Typography variant="body3">Greater or equal</Typography>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <TextInput
                  name={`configuration.parameters[${index}].alert_level`}
                  label="Alert Level"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Typography variant="body3">{parameter.unit}</Typography>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

function PerimeterParameters() {
  const { isFetching, parameterOptions } = useDataPoints();
  const {
    values: {
      configuration: { parameters = [] }
    }
  } = useFormState({ subscription: { values: true } });

  return (
    <FormGrid columns={2}>
      {parameters.map(({ name }, index) => (
        <PerimeterParameter key={name} index={index} parameterOptions={parameterOptions} loading={isFetching} />
      ))}
    </FormGrid>
  );
}

export default observer(PerimeterParameters);
