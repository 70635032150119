/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import BaseConfiguration from '~/mst/models/report/configuration/base';
import AQConfiguration from '~/mst/models/report/configuration/aq_report';
import PerimeterMonitor from '~/mst/models/report/configuration/perimeter_monitor_report';
import { ReportTypes } from '~/mst/models/report/types';

const ReportConfiguration = t.union({
  dispatcher(snapshot) {
    if (snapshot?.type === ReportTypes.air_quality || snapshot?.type === ReportTypes.air_quality_crp) {
      return AQConfiguration;
    }
    if (snapshot?.type === ReportTypes.perimeter_monitor) {
      return PerimeterMonitor;
    }
    return BaseConfiguration;
  }
});

export interface ICondition extends Instance<typeof ReportConfiguration> {}

export default t.snapshotProcessor(ReportConfiguration, {
  preProcessor(values) {
    const result = { ...values };
    if (values.standard === 'aqi') {
      result.standard = 'aethair_blue';
    }
    if (values.type === 'aqi_basic' || values.type === 'aqi_extended') {
      result.type = ReportTypes.air_quality;
      if (values.type === 'aqi_extended') {
        result.view = 'extended';
      } else {
        result.view = 'basic';
      }
    }
    return result;
  },
  postProcessor(sn) {
    const result = { ...sn };
    if (sn.standard === 'aqi') {
      result.standard = 'aethair_blue';
    }
    if (sn.type === 'aqi_basic' || sn.type === 'aqi_extended') {
      result.type = ReportTypes.air_quality;
      if (sn.type === 'aqi_extended') {
        result.view = 'extended';
      } else {
        result.view = 'basic';
      }
    }
    return result;
  }
});
