/* eslint-disable @typescript-eslint/naming-convention */
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import min from 'lodash/min';
import max from 'lodash/max';
import mean from 'lodash/mean';
import round from 'lodash/round';
import isObject from 'lodash/isObject';

import { IDataPointModel } from './model';

export default (self: IDataPointModel) => {
  function getPrecision() {
    if (self.isMetric) {
      return Math.max(...self.linked.map((dp) => dp?.round));
    }
    return isNil(self.round) ? 3 : self.round;
  }
  function calculate(func) {
    const values = self.measurements.map((measurement) => measurement[1]);
    const value = round(func(values), getPrecision());
    return !Number.isNaN(value) ? value : 'N/A';
  }
  return {
    get _measurements() {
      return sortBy(
        uniqBy(self.measurements, ([ts]: any[]) => ts),
        ([ts]) => ts
      );
    },
    get lastValue() {
      const value = get(last(self._measurements), '[1]');
      if (self.isMetric && Array.isArray(value)) {
        return value[0];
      }
      if (isObject(value) && !Array.isArray(value)) {
        return JSON.stringify(value);
      }
      // @ts-ignore
      return value;
    },
    get lastTs() {
      // @ts-ignore
      return get(last(self._measurements), '[0]');
    },
    get precision() {
      return getPrecision();
    },
    get min() {
      return calculate(min);
    },
    get max() {
      return calculate(max);
    },
    get avg() {
      return calculate(mean);
    },
    get data() {
      return self.measurements.map(([ts, value]) => (Array.isArray(value) ? [ts, value[0]] : [ts, value]));
    }
  };
};
