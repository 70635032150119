/* eslint-disable import/prefer-default-export */
import request from '../request';

const { VITE_ANALYTICS_URL_ROOT } = process.env;

export function fetch(params, options) {
  return [request, 'get', 'analytics/reports', params, options];
}

export function create(payload: any) {
  return request('post', `analytics/reports`, payload);
}

export function update(id, payload: any) {
  return request('put', `analytics/reports/${id}`, payload);
}

export function destroy(id: string) {
  return request('delete', `analytics/reports/${id}`);
}

export function downloadPdf(reportId: string) {
  return request('get', `reports/${reportId}?print_pdf=true`, null, {
    responseType: 'blob',
    baseURL: VITE_ANALYTICS_URL_ROOT
  });
}

export function read(id: string) {
  return request('get', `reports/${id}`, null, { baseURL: VITE_ANALYTICS_URL_ROOT, headers: { accept: 'application/json' } });
}
