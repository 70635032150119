import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { autorun } from 'mobx';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts/highstock';
import 'highcharts/modules/no-data-to-display';
import { observer } from 'mobx-react-lite';

import { getChartOptions } from '~/components/chart/chart_options';
import type { IDevicesOnlineChartWidgetStore } from '../store';
import { defaultChartOptions } from './chart_options';

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

function Chart({ widget }: { widget: IDevicesOnlineChartWidgetStore }) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const containerRef = useRef(null);
  const theme = useTheme();
  const [options, setChartOptions] = useState(defaultChartOptions);

  useEffect(
    () =>
      autorun(() => {
        if (widget.isFetched) {
          setChartOptions(
            getChartOptions(
              {
                series: widget?.chartData
              },
              theme.palette.mode
            )
          );
        }
      }),
    [widget, theme.palette.mode]
  );
  return (
    <Box ref={containerRef} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} containerProps={{ style: { height: '100%' } }} options={options} />
    </Box>
  );
}

export default observer(Chart);
