import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { autorun } from 'mobx';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts/highstock';
import 'highcharts/modules/no-data-to-display';
import { observer } from 'mobx-react-lite';

import useMst from '~/hooks/use_mst';
import { getChartOptions } from '~/components/chart/chart_options';
import type { ITotalDevicesWidget } from '../store';
import { defaultChartOptions } from './column_chart_options';

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

function Chart({ widget }: { widget: ITotalDevicesWidget }) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef(null);
  const [options, setChartOptions] = useState(defaultChartOptions);
  const theme = useTheme();
  const { stats } = useMst();

  useEffect(
    () =>
      autorun(() => {
        if (widget.isFetched) {
          setChartOptions(
            getChartOptions(
              {
                series: widget?.columnChartSeries,
                xAxis: {
                  categories: widget?.columnChartCategories
                }
              },
              theme.palette.mode
            )
          );
        }
      }),
    [widget, stats, theme.palette.mode]
  );
  return (
    <Box ref={containerRef} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} containerProps={{ style: { height: '100%' } }} options={options} />
    </Box>
  );
}

export default observer(Chart);
