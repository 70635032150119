/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef } from 'react';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import snakeCase from 'lodash/snakeCase';
import {
  SortingState,
  RowDetailState,
  IntegratedSorting,
  SummaryState,
  IntegratedSummary,
  GroupingState,
  IntegratedGrouping,
  IntegratedPaging,
  PagingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableRowDetail,
  TableSummaryRow,
  TableGroupRow,
  TableColumnVisibility,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useExpandRow from '~/hooks/react_grid/use_expand_row';
import usePaging from '~/hooks/react_grid/use_paging';

import I18n from '~/utils/i18n';
import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useExport from '~/hooks/react_grid/use_export';
import type { IAlert } from '~/mst/models/alert';
import TriggeredFormatter from '~/components/react_grid/triggered_formatter';
import GridDetailContainer from './detail_row';
import { DurationProvider, TriggeredProvider } from './info_providers';
import type { IChartTableWidget } from '../../store/chart_table_widget';
import type { IAlertMonitor } from '../../store';
import DurationFormatter from './duration_formatter';

function compareDates(a: number, b: number) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

export const columns = [
  { name: 'name', title: 'Condition' },
  { name: 'from', title: I18n.t('alerts.durations.triggered') },
  {
    name: 'duration',
    title: I18n.t('alerts.durations.duration')
  }
];

const getRowId = ({ name, from }) => `${name}-${from}`;
const customizeCell = (cell, row, column) => {
  if (column.name === 'duration') {
    cell.value = DurationFormatter({ value: row.duration }); // eslint-disable-line no-param-reassign
  }
  if (column.name === 'from') {
    cell.value = TriggeredFormatter({ value: row.from }); // eslint-disable-line no-param-reassign
  }
};

function AlertTable({ widget, monitor, alert }: { widget: IChartTableWidget; monitor: IAlertMonitor; alert: IAlert }) {
  const { expandedRowIds, handleSetExpandedRowIds } = useExpandRow();
  const { sorting, handleSortingChange } = useSorting([
    { columnName: 'name', direction: 'asc' },
    { columnName: 'from', direction: 'asc' }
  ]);
  const [grouping] = useState([{ columnName: 'name' }]);
  const [columnExtensionsSorting] = useState([{ columnName: 'from', compare: compareDates }]);

  const [totalSummaryItems] = useState([
    { columnName: 'from', type: 'count' },
    { columnName: 'duration', type: 'sum' }
  ]);

  const [groupSummaryItems] = useState([
    { columnName: 'from', type: 'count', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'duration', type: 'sum', showInGroupFooter: false, alignByColumn: true }
  ]);
  const { messages, RootComponent } = useLoading(widget);

  const { pageSizes, pageSize, handlePageSizeChange } = usePaging();

  const oneCondition = widget.data_points.size === 1;
  const exporterRef = useRef(null);

  const { isExporting, stopExport } = useExport();

  useEffect(() => {
    if (isExporting) {
      stopExport();
      exporterRef.current.exportGrid();
    }
    return () => stopExport();
  }, [isExporting, exporterRef, stopExport]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${snakeCase(alert?.name)}_${snakeCase(monitor.range.presentName)}.xlsx`);
    });
  };

  return (
    <GridStyled>
      <Grid rows={widget.tableData} columns={columns} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={columnExtensionsSorting} />
        {oneCondition && <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />}
        {oneCondition && <IntegratedPaging />}
        <DurationProvider />
        <TriggeredProvider />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <GroupingState grouping={oneCondition ? [] : grouping} />
        <SummaryState totalItems={totalSummaryItems} groupItems={groupSummaryItems} />
        <IntegratedGrouping />
        <IntegratedSummary />
        <VirtualTable containerComponent={Container} messages={messages} height="auto" />
        {oneCondition && <TableColumnVisibility defaultHiddenColumnNames={['name']} />}
        <TableRowDetail rowComponent={GridDetailContainer} />
        <TableHeaderRow showSortingControls />
        {oneCondition && widget.tableData.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
        <TableGroupRow
          showColumnsWhenGrouped
          messages={{
            sum: 'Duration',
            count: 'State Changes'
          }}
        />
        <TableSummaryRow
          messages={{
            sum: 'Total Duration',
            count: 'Total State Changes'
          }}
        />
      </Grid>
      <GridExporter
        getRowId={getRowId}
        sorting={sorting}
        ref={exporterRef}
        columns={columns}
        customizeCell={customizeCell}
        rows={widget.tableData}
        onSave={onSave}
      />
    </GridStyled>
  );
}

export default observer(AlertTable);
