import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en';
import es from '../locales/es';

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    es: { translation: es }
  },
  lng: 'en-US',
  interpolation: { prefix: '%{', suffix: '}', escapeValue: false },
  fallbackLng: 'en-US',
  nsSeparator: '::'
});

export default i18next;
