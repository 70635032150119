import { AxiosRequestConfig } from 'axios';
import axios, { token } from './axios';

const { VITE_API_URL_ROOT } = process.env;
/* eslint-enable no-param-reassign */

type RequestOptions = Partial<{
  signal: any;
  api: boolean;
}>;

const request = (method: string, url: string, params: any = {}, options: RequestOptions = {}) => {
  const body = method === 'get' ? 'params' : 'data';
  const { api = true, signal, ...rest } = options;

  const config = {
    method,
    url,
    baseURL: VITE_API_URL_ROOT,
    [body]: params || {},
    ...rest
  } as AxiosRequestConfig;

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (api) {
    config.headers.Authorization = token.getAppToken();
  }

  return new Promise((resolve, reject) => {
    axios
      .request({
        ...config,
        ...(signal && { signal })
      })
      .then((response) => {
        resolve({ ...response, fromCache: false });
      })
      .catch((error) => reject(error));
  });
};

export default request;
